import React, { useEffect } from 'react'
import '../assets/css/CompanyCard.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const CompanyCard = ({ logo, url, aosDelay }) => {

  useEffect(() => {
    AOS.init({
      once: true,
      mirror: true,
      easing: 'ease-out-back'
    });
  }, []);


  return (
    <a href={url}
      target="_blank" className="companyCard" data-aos="fade-up" data-aos-delay={aosDelay} data-aos-duration="1000"><img
        src={logo} alt="" className="img-fluid" /></a>
  )
}
