import React from 'react'
import '../assets/css/SectionStats.css';
import imgDots from '../assets/img/frame-dots.svg';
import imgShadowStats from '../assets/img/shadow-stats.png';
import CountUp from 'react-countup';

export const SectionStats = () => {
  return (
    <section className="stats">
      <div className="width-wrapper">
        <div className="content-wrapper">
          <div className="item">
            <h5 className="name"><span className="counter-3"><CountUp end={3} enableScrollSpy={true} scrollSpyOnce={true} /></span> años</h5>
            <p className="desc">creando contenido</p>
          </div>
          <div className="item">
            <h5 className="name">+<span className="counter-2"><CountUp end={1.1} decimals={1} enableScrollSpy={true} scrollSpyOnce={true} /></span>M</h5>
            <p className="desc">seguidores</p>
          </div>
          <div className="item">
            <h5 className="name">+<span className="counter"><CountUp end={50} enableScrollSpy={true} scrollSpyOnce={true} /></span>M</h5>
            <p className="desc">de visitas en las redes</p>
          </div>
        </div>
        <img src={imgShadowStats} alt="" className="img-fluid bg-shadow" />
      </div>
      <img src={imgDots} alt="" className="dots img-fluid" />
    </section>
  )
}
