import React from 'react'
import '../assets/css/Header.css'
import jovenInversorLogo from "../assets/img/joveninversor-logo.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons'
import { Link, useLocation } from 'react-router-dom';
import { ButtonTheme } from './ButtonTheme';
import { SidebarToggler } from './SidebarToggler';


const Header = () => {
    const navbarBrandStyle = {
        display: 'inline-block',
        paddingTop: '0.3125rem',
        paddingBottom: '0.3125rem',
        marginRight: '0rem',
        fontSize: '1.25rem',
        lineHeight: 'inherit',
        whiteSpace: 'nowrap',
    }
    const location = useLocation();

    const getCoursesNav = () => {
        if (location.pathname.search('cursos') !== -1) {
            return (<SidebarToggler />);
        }
        return (<></>);
    }

    return (
        <header className="sticky-top fixed-top">
            <nav className="navbar navbar-expand-lg navbar-light ">
                <div className="container">
                    {getCoursesNav()}
                    <Link to="/" style={navbarBrandStyle}><img src={jovenInversorLogo} alt=""
                        className="img-fluid navbar-brand" /></Link>
                    <ul className="navbar-nav ml-auto flex-row">
                        <li className="nav-item d-none d-lg-block">
                            <Link to="/">¿Quien soy?</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/cursos/el-mercado-para-principiantes/modulo-0/introduccion">Mis cursos</Link>
                        </li>

                        <li className="nav-item social-links">
                            <a href="https://www.instagram.com/joveninversor/" target="_blank" rel='noreferrer' className="social-link"> <FontAwesomeIcon icon={faInstagram} /></a>
                            <a href="https://www.youtube.com/@JovenInversor" target="_blank" rel='noreferrer' className="social-link"><FontAwesomeIcon icon={faYoutube} /></a>
                            <a href="https://www.tiktok.com/@joveninversor" target="_blank" rel='noreferrer' className="social-link"><FontAwesomeIcon icon={faTiktok} /></a>
                        </li>

                        <li className="nav-item theme">
                            <ButtonTheme />
                        </li>

                    </ul>
                </div>
            </nav>
        </header >
    )
}

export default Header