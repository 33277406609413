import React from 'react';
import Header from '../Header';
import SectionVideo from '../SectionVideo';
import SectionMoment from '../SectionMoment';
import { SectionStats } from '../SectionStats';
import { SectionAboutme } from '../SectionAboutme';
import { SectionCompanies } from '../SectionCompanies';
import { Footer } from '../Footer';

export const Home = () => {
  return (
    <>
      <Header />
      <SectionVideo />
      <SectionMoment />
      <SectionStats />
      <SectionAboutme />
      <SectionCompanies />
      <Footer />
    </>
  )
}
