import React, { useState } from 'react'
import { ButtonDonations } from './ButtonDonations';
import imgMP from '../assets/img/ji-mp.jpeg';
import imgQR from '../assets/img/ji-donation-qr.png';
import '../assets/css/ContainerDonations.css'

export const ContainerDonations = () => {
  const [infoVisible, setInfoVisible] = useState(false);

  const toggleInfo = () => {
    if (infoVisible) {
      setInfoVisible(false);
    } else {
      setInfoVisible(true);
    }
  }

  return (
    <section className="donations">
      <ButtonDonations toggleInfo={toggleInfo} />
      {
        infoVisible &&
        <div className='donations_info mt-3'>
          <div className="row">
            <div className="col-lg-6">
              <h5 className="title">DONÁ CON CRIPTO</h5>
              <img src={imgQR} className="img-fluid donation-img" alt="" />
              <p className="address"><b>Dirección:</b> TPFfYoJNBBc3vw3hBGSwRWKSo7VeGYRqeQ</p>
            </div>
            <div className="col-lg-6">
              <h5 className="title">DONÁ CON MERCADOPAGO</h5>
              <img src={imgMP} className="img-fluid donation-img" alt="" />
              <p className="address"><b>Alias:</b> agustinnatoli.mp</p>
              <p className="address"><b>CVU:</b> 0000003100096633472358</p>
            </div>
          </div>
        </div>
      }
    </section>
  )
}
