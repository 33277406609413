import React, { useEffect } from 'react'
import { CompanyCard } from './CompanyCard'
import oracleLogo from '../assets/img/companies/orable_numeris.png';
import metamindLogo from '../assets/img/companies/metamind.png';
import tiendaLogo from '../assets/img/companies/crypto_tienda.png';
import coinboxLogo from '../assets/img/companies/coinbox.png';
import { CompanyContainer } from './CompanyContainer';
import '../assets/css/SectionCompanies.css';
import imgArch from '../assets/img/arch.svg';
import ButtonCourses from './ButtonCourses';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const SectionCompanies = () => {

  useEffect(() => {
    AOS.init({
      once: true,
      mirror: true,
      easing: 'ease-out-back'
    });
  }, []);

  return (
    <section className="companies">
      <h2 className="title" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">Mis unidades de negocio</h2>

      <CompanyContainer>
        <CompanyCard aosDelay={20} logo={oracleLogo} url="https://www.instagram.com/oracle.numeris/" />
        <CompanyCard aosDelay={40} logo={metamindLogo} url="https://metamind.la/" />
        <CompanyCard aosDelay={60} logo={coinboxLogo} url="https://coinboxmining.com/" />
        <CompanyCard aosDelay={80} logo={tiendaLogo} url="https://tiendacripto.io/" />
      </CompanyContainer>

      <div className="courses">
        <div className="container">
          <h5 className="label" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">No invertir es un
            error, pero hacerlo sin conocimiento es un problema mucho mas grande...
          </h5>
          <ButtonCourses aosEnabled={true} aosDelay={100} />
        </div>
      </div>

      <img src={imgArch} alt="" className="img-fluid arch" />
    </section>
  )
}
