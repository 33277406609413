import React from 'react'

export const SidebarCategory = ({ name, children }) => {
  return (
    <div className='sidebar_category'>
      <h3 className="sidebar_category_name">{name}</h3>
      {children}
    </div>
  )
}
