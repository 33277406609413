import React, { useContext } from 'react'
import '../assets/css/SidebarToggler.css';
import { ThemeContext } from '../App';
import hambImg from '../assets/img/navbar-bars.svg';

export const SidebarToggler = () => {

  const { sidebar, setSidebar } = useContext(ThemeContext);

  const toggleSidebar = () => {
    if (sidebar === 'on') {
      setSidebar('off');
    } else {
      setSidebar('on');
    }
  }

  return (
    <div className='sidebar_toggler' onClick={toggleSidebar}><img src={hambImg} alt="" className="img-fluid mr-3" /></div>
  )
}
