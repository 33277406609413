import React, { useEffect } from 'react'
import '../assets/css/ButtonCourses.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const ButtonCourses = ({ className = '', aosDelay, aosEnabled = false }) => {

  useEffect(() => {
    if (aosEnabled) {
      AOS.init({
        once: true,
        mirror: true,
        easing: 'ease-out-back'
      });
    }
  }, []);

  return (
    // <a href="" className={"btn btn-courses " + className} data-aos="fade-up"
    //   data-aos-delay={aosDelay} data-aos-duration="1000" > Ver
    //   cursos gratis</a >
    <div className={"btn-courses " + className} data-aos="fade-up" data-aos-delay={aosDelay} data-aos-duration="1000">
      <Link to="/cursos/el-mercado-para-principiantes/modulo-0/introduccion">Ver cursos gratis</Link>
    </div>
  )
}

export default ButtonCourses