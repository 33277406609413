import React from 'react'
import jovenInversorLogo from "../assets/img/joveninversor-logo.svg";
import '../assets/css/Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons'

export const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div>
          <img src={jovenInversorLogo} alt="" className="img-fluid logo" />
          <p className="contact">CONTACTO</p>
          <a href="mailto:agus@joveninversor.com" target="_blank" rel='noreferrer' className="mail">agus@joveninversor.com</a>
        </div>
        <div>
          <a href="https://www.instagram.com/joveninversor/" target="_blank" rel='noreferrer' className="social-link"><FontAwesomeIcon icon={faInstagram} /></a>
          <a href="https://www.youtube.com/@JovenInversor" target="_blank" rel='noreferrer' className="social-link"><FontAwesomeIcon icon={faYoutube} /></a>
          <a href="https://www.tiktok.com/@joveninversor" target="_blank" rel='noreferrer' className="social-link"><FontAwesomeIcon icon={faTiktok} /></a>
        </div>
      </div>
    </footer>
  )
}
