import React from 'react'
import '../assets/css/SectionVideo.css'
import archImg from "../assets/img/arch.svg";
import Vimeo from '@u-wave/react-vimeo';

const SectionVideo = () => {
  return (
    <section className="video mt-4">
      <div className="container">
        <div className="embed-responsive embed-responsive-16by9">
          <Vimeo video="1019511294" muted autoplay color='ff9c00' loop />
        </div>
      </div>
      <img src={archImg} alt="" className="img-fluid circle"></img>
    </section>
  )
}

export default SectionVideo