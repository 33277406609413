import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import coursesData from '../../assets/data/courses.json';
import { Footer } from '../Footer';
import Header from '../Header';
import { SectionCourseInfo } from '../SectionCourseInfo';
import { SectionCourseVideo } from '../SectionCourseVideo';
import { Sidebar } from '../Sidebar';

export const Courses = () => {


  const { categoryId, courseId, courseSlug } = useParams();
  const [categoryData, setCategoryData] = useState();
  const [courseData, setCourseData] = useState();
  const [videoData, setVideoData] = useState();

  const [nextVideo, setNextVideo] = useState(null);
  const [prevVideo, setPrevVideo] = useState(null);

  useEffect(() => {
    setCategoryData(coursesData.find(element => element.id === categoryId));
    setCourseData(categoryData?.content.find(element => element.id === courseId));
    setVideoData(courseData?.videos.find(element => element.slug === courseSlug));
  }, [categoryData, courseData, videoData, categoryId, courseId, courseSlug]);

  useEffect(() => {

    if (!categoryData && !courseData) {
      return;
    }

    let currentIndex = categoryData?.content.findIndex(element => element.id === courseData?.id);
    if (currentIndex >= (categoryData?.content.length - 1)) {
      setNextVideo(null);
    } else {
      setNextVideo(categoryData?.content[currentIndex + 1]);
    }

    if (currentIndex !== 0) {
      setPrevVideo(categoryData?.content[currentIndex - 1]);
    } else {
      setPrevVideo(null);
    }
  }, [courseData, categoryData]);

  return (
    <>
      <Header />
      <Sidebar />
      <SectionCourseVideo videoUrl={videoData?.url} />
      <SectionCourseInfo title={videoData?.title} subtitle={videoData?.subtitle} description={videoData?.description} nextVideo={nextVideo} prevVideo={prevVideo} />
    </>
  )
}
