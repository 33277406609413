import React, { useState, useEffect } from 'react'
import '../assets/css/SectionCourseVideo.css';
import { Oval } from 'react-loader-spinner';

export const SectionCourseVideo = ({ videoUrl }) => {

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
  }, [videoUrl])

  const handleVideoLoad = () => {
    setIsLoading(false);
  }
  return (
    <section className='course-video'>
      <div className="video-wrapper pb-4 pt-4">
        <div className="embed-responsive embed-responsive-16by9">
          {isLoading && (
            <div className="loader-wrapper">
              <Oval
                height={40}
                width={40}
                color="#7a7a7a"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#ccc"
                strokeWidth={2}
                strokeWidthSecondary={2}

              />
            </div>
          )}
          <iframe className="embed-responsive-item" style={{ visibility: isLoading ? 'hidden' : 'visible' }} src={videoUrl} onLoad={handleVideoLoad}></iframe>
        </div>
      </div>
    </section>
  )
}
