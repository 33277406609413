import React, { useContext } from 'react'
import '../assets/css/ButtonTheme.css';
import sunImg from '../assets/img/sunOutline.svg';
import moonImg from '../assets/img/moonOutline.svg';
import { ThemeContext } from '../App';

export const ButtonTheme = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  const changeTheme = (() => {
    if (theme == 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  })

  return (
    <button type='button' onClick={changeTheme} className={"btn-theme " + theme}>
      <img className='img-fluid' src={theme === 'light' ? sunImg : moonImg} alt="" />
    </button>
  )
}
