import React from 'react'
import '../assets/css/SectionAboutme.css'
import imgAgustin from '../assets/img/agustinnatoli.jpg';
import imgShadow from '../assets/img/shadow-me.png';
import imgShadowDark from '../assets/img/shadow-me-dark.png';
import { useContext, useEffect } from 'react';
import { ThemeContext } from '../App';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const SectionAboutme = () => {

  useEffect(() => {
    AOS.init({
      once: true,
      mirror: true,
      easing: 'ease-out-back'
    });
  }, []);

  const { theme } = useContext(ThemeContext);

  return (
    <section className="about-me" id="quien-soy">
      <div className="width-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-1"><img
              src={imgAgustin}
              alt="" className="img-fluid photo" data-aos="fade-up" data-aos-delay="0"
              data-aos-duration="1000" /></div>
            <div className="col-lg-5">
              <h3 className="title" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">¿Quién soy?
              </h3>
              <p className="desc" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">Agus Natoli.
                <br />
                Estudiante Lic. En Economia, creador del canal sobre finanzas e inversiones llamado Joven
                Inversor. <br />
                Apasionado de las inversiones, el mercado financiero y el analisis tecnico, busco enseñar
                todo lo que aprendi de forma gratuita a traves de las redes.</p>
            </div>
          </div>
        </div>
        <img src={theme === 'light' ? imgShadow : imgShadowDark} alt="" className="img-fluid bg-shadow" />
      </div>
    </section>
  )
}
