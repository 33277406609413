import React from 'react'
import '../assets/css/CompanyContainer.css';

export const CompanyContainer = ({ children }) => {
  return (
    <div className="CompanyContainer">
      {children}
    </div>
  )
}
