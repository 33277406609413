import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom';
import coursesData from '../assets/data/courses.json';
import { SidebarCategory } from './SidebarCategory';
import '../assets/css/Sidebar.css';
import { ThemeContext } from '../App';
export const Sidebar = () => {

  const { sidebar, setSidebar } = useContext(ThemeContext);

  const getCategoryVideos = (category => {

    return (
      <React.Fragment key={Math.random()}>
        {
          category.content.map(course => {
            return (
              <div key={Math.random()}>
                {course.videos.map(courseVideo => {
                  return (
                    <div className='sidebar_link'>
                      <NavLink className={({ isActive, isPending }) =>
                        isPending ? "pending" : isActive ? "active" : ""
                      } to={"/cursos/" + courseVideo.path}>{courseVideo.title}</NavLink>
                    </div>
                  );
                })}
              </div>
            );
          })
        }
      </React.Fragment>
    );
  })

  return (
    <div className={'sidebar ' + sidebar}>
      {
        coursesData.map(category => {
          return (
            <SidebarCategory key={category.id} name={category.label}>
              {
                getCategoryVideos(category)
              }
            </SidebarCategory>
          );
        })
      }
    </div>
  )


}
