import React from 'react'
import { Link } from 'react-router-dom';
import '../assets/css/SectionCourseInfo.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { ContainerDonations } from './ContainerDonations';

export const SectionCourseInfo = ({ title, subtitle, description, nextVideo, prevVideo }) => {
  return (
    <section className='course-info'>
      <div className="content-wrapper">
        <div className="d-flex flex-row align-items-center justify-content-between mt-2 mb-2">
          <Link className={"btn btn-prev " + (!prevVideo ? 'disabled' : '')} to={prevVideo && "/cursos/" + prevVideo.videos[0].path}><FontAwesomeIcon className='mr-1' icon={faChevronLeft} /> Anterior</Link>
          <Link className={"btn btn-next " + (!nextVideo ? 'disabled' : '')} to={nextVideo && "/cursos/" + nextVideo.videos[0].path}>Siguiente <FontAwesomeIcon className='ml-1' icon={faChevronRight} /></Link>
        </div>
        <div className="title-wrapper">
          <small className="course-detail">{subtitle}</small>
          <h1>{title}</h1>
        </div>

        <p className="desc">{description}</p>
        <p className="donations">Si sentís que te sirvieron los cursos, dejamos a disposición la posibilidad de realizar una donación. La idea con éstas es poder cubrir los costos que tuvimos para realizar todo el contenido ¡Gracias!

        </p>
        <ContainerDonations />
      </div>
    </section>
  )
}
