import React, { useEffect } from 'react'
import '../assets/css/SectionMoment.css'
import ButtonCourses from './ButtonCourses'
import imgCoins from '../assets/img/coins.png';
import imgSemicirlce from '../assets/img/semi_circle.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons'
import AOS from 'aos';
import 'aos/dist/aos.css';

const SectionMoment = () => {

  useEffect(() => {
    AOS.init({
      once: true,
      mirror: true,
      easing: 'ease-out-back'
    });
  }, []);

  return (
    <section className="moment">
      <div className="container">
        <h1 className="title" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">No hay mejor inversión que uno mismo</h1>
        <div className="row">
          <div className="col-lg-5">
            <h2 className="subtitle" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">Empezar a invertir requiere conocimiento
            </h2>
            <p className="desc" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">Que mejor que obtener ese conocimiento de forma gratuita</p>
            <ButtonCourses className="d-none d-lg-block" aosEnabled={true} aosDelay={300} />
            <div className='text-center mt-3 d-none d-lg-block' data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
              <a href="https://www.instagram.com/joveninversor/" target="_blank" className="social-link"> <FontAwesomeIcon icon={faInstagram} /></a>
              <a href="https://www.youtube.com/@JovenInversor" target="_blank" className="social-link"><FontAwesomeIcon icon={faYoutube} /></a>
              <a href="https://www.tiktok.com/@joveninversor" target="_blank" className="social-link"><FontAwesomeIcon icon={faTiktok} /></a>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1">
            <img src={imgCoins} data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000"
              alt="" className="img-fluid img-coins" />
            <ButtonCourses className="d-lg-none" aosEnabled={true} aosDelay={100} />
            <div className='text-center mt-3 d-lg-none' data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
              <a href="https://www.instagram.com/joveninversor/" target="_blank" className="social-link"> <FontAwesomeIcon icon={faInstagram} /></a>
              <a href="https://www.youtube.com/@JovenInversor" target="_blank" className="social-link"><FontAwesomeIcon icon={faYoutube} /></a>
              <a href="https://www.tiktok.com/@joveninversor" target="_blank" className="social-link"><FontAwesomeIcon icon={faTiktok} /></a>
            </div>
          </div>
        </div>
      </div>
      <img src={imgSemicirlce} alt="" className="img-fluid circle" />
    </section>
  )
}

export default SectionMoment