import React, { useState, createContext } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { Courses } from './components/views/Courses';
import { NotFound } from './components/views/NotFound';
import { Home } from './components/views/Home';

export const ThemeContext = createContext();

export const App = () => {
  const [theme, setTheme] = useState('light');
  const [sidebar, setSidebar] = useState('on');

  return (
    <BrowserRouter>
      <ThemeContext.Provider value={{ theme, setTheme, sidebar, setSidebar }}>
        <div id='app' className={theme}>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/home" element={<Home />} />
            <Route path="/cursos/:categoryId/:courseId/:courseSlug" element={<Courses />} />
            <Route path="*" component={<NotFound />} />
          </Routes>
        </div>
      </ThemeContext.Provider>
    </BrowserRouter>
  )
}
